import {shape, string} from 'prop-types'
import classNames from 'classnames'
import loadable from '@loadable/component'
import React from 'react'

import Media from 'components/UI/Media'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'

import useStyles from './styles'

const HeroCompany = ({
  hat,
  url,
  title,
  longDescription,
  parallaxMediaType,
  image,
  headerCta,
  headerCtaLink,
  hasCta,
}) => {
  const classes = useStyles()
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const renderDecorations = () => (
    <Decorations>
      <Decoration webSiteName="keyrus" color="blue" top={52} />
      <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
      <Decoration webSiteName="keyrus" color="red" right={0} bottom={-160} />
      <Decoration webSiteName="keyrus-management" color="blue" top={52} />
      <Decoration
        webSiteName="keyrus-management"
        color="orange"
        right={0}
        top={60}
      />
      <Decoration
        webSiteName="keyrus-management"
        color="red"
        right={0}
        bottom={-160}
      />
      <Decoration webSiteName="kls" svg="left" top={200} />
      <Decoration webSiteName="kls" svg="right" right={0} top={208} />
    </Decorations>
  )

  const mediaIsVideo =
    parallaxMediaType === null || parallaxMediaType === 'Video'
  const mediaIsImage = parallaxMediaType && parallaxMediaType === 'Image'

  return (
    <div>
      {mediaIsVideo && (
        <video
          className={classes.heroVideo}
          type="video/mp4"
          src={url}
          loop
          autoPlay
          muted
          playsInline
        />
      )}
      {mediaIsImage && (
        <div
          className={classes.heroVideo}
          style={{
            backgroundImage: `url(${image.file.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Media data={image} className={classes.heroVideo} />
        </div>
      )}

      <div className={classes.container}>
        <div className={classNames('decorationWrapper', classes.Decoration)}>
          {renderDecorations()}
          <div className={classes.heroTextContainer}>
            <div className={classes.heroText}>
              <Title hat={hat}>{title}</Title>
              <RawText text={longDescription} />
              {hasCta && (
                <div style={{marginTop: 16}}>
                  <RoundButton
                    href={headerCtaLink}
                    arrow
                    isCentered
                    isNowrap
                    color="secondary"
                    externalLink
                  >
                    {headerCta}
                  </RoundButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HeroCompany.propTypes = {
  hat: string,
  image: shape({
    file: {
      url: string,
    },
  }),
  longDescription: shape({
    raw: string,
  }),
  parallaxMediaType: string,
  title: string,
  url: string,
}

HeroCompany.defaultProps = {
  hat: '',
  image: shape({
    file: {
      url: '',
    },
  }),
  longDescription: shape({
    raw: '',
  }),
  parallaxMediaType: '',
  title: '',
  url: '',
}

export default HeroCompany
