import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, shadow, spacing, breakpoints}) => ({
  heroVideo: {
    minWidth: '100%',
    height: '600px',
    position: 'absolute',
    zIndex: -2,
    objectFit: 'cover',
    [breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      position: 'relative',
    },
  },
  container: {
    [breakpoints.up('md')]: {
      paddingTop: spacing(50),
    },
  },
  heroTextContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  heroText: {
    backgroundColor: palette.background.default,
    width: '960px',
    borderRadius: spacing(1, 5),
    boxShadow: shadow.keyplayHeader,
    padding: spacing(10, 12),
    zIndex: 3,
    [breakpoints.down('sm')]: {
      padding: spacing(5, 3),
    },
  },
  decorations: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default useStyles
