import * as R from 'ramda'
import {graphql, navigate} from 'gatsby'
import React, {useEffect, useState} from 'react'

import {CompanyPurposePropTypes} from 'helpers/propTypes'
import {stringToSlug} from 'helpers/utils'
import ContentSection from 'components/UI/ContentSection'
import HeroCompany from 'components/AboutUs/CompanyPurpose'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const CompanyPurpose = ({pageContext, data, location}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const mapIndexed = R.addIndex(R.map)
  const {hash} = location
  const [ref, setRef] = useState(false)

  const {
    metaTitle,
    video: {
      file: {url},
    },
    title,
    shortDescription,
    longDescription,
    contentsSections1,
    contentSections2,
    surtitle,
    parallaxMediaType,
    image,
  } = R.pathOr(null, ['allContentfulCompanyPurpose', 'nodes', 0], data)

  setTimeout(() => {
    const domElement = hash && document.querySelector(hash)

    if (domElement) {
      setRef(domElement)
    }
  }, 100)

  useEffect(
    () => () => {
      if (ref) {
        ref.scrollIntoView({
          alignToTop: false,
          behavior: 'smooth',
          block: 'center',
        })
      }
    },
    [ref],
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['shortDescription'], shortDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  useEffect(
    () => () => {
      if (process.env.GATSBY_WEBSITE === 'kls') {
        if (hash === '#neuro-team') {
          navigate('../management/')
        }
        if (hash === '#ourlocations') {
          navigate('../../contact-us/')
        }
      }
    },
    [],
  )

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <HeroCompany
        hat={surtitle}
        url={url}
        title={title}
        longDescription={longDescription}
        parallaxMediaType={parallaxMediaType}
        image={image}
      />
      <Section hasSmallPadding hasPaddingBottom={false}>
        {R.map(node => {
          const slug = stringToSlug(node.title)

          return (
            node.description && (
              <ContentSection
                title={node.title}
                variation={node.variation}
                description={node.description}
                anchor={slug}
              />
            )
          )
        }, contentSections2)}
      </Section>
      <Section>
        {mapIndexed(node => {
          const slug = stringToSlug(node.title)

          return (
            <ContentSection
              title={node.title}
              image={node.image}
              variation={node.variation}
              description={node.description}
              anchor={slug}
            />
          )
        }, contentsSections1)}
      </Section>
    </>
  )
}

CompanyPurpose.propTypes = CompanyPurposePropTypes

CompanyPurpose.defaultProps = {}

export const pageQuery = graphql`
  query templateCompanyPurpose(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulCompanyPurpose(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        node_locale
        surtitle
        metaTitle
        parallaxMediaType
        image {
          gatsbyImageData
          file {
            url
          }
        }
        video {
          file {
            url
          }
        }
        title
        shortDescription {
          shortDescription
        }
        longDescription {
          raw
        }
        contentsSections1 {
          image {
            file {
              url
              contentType
            }
          }
          variation
          description {
            raw
          }
          title
        }
        contentSections2 {
          variation
          description {
            raw
          }
          title
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
  }
`

export default CompanyPurpose
